<template>
  <v-app :class="theme">
    <Header v-if="user" />

    <Navigation v-if="user" />

    <v-main :class="theme">
      <Auth v-if="showSignIn" />

      <router-view
        v-else-if="user && isValidRoute"
        class="fill-height"
      />

      <v-card
        v-else
        class="ma-3"
      >
        <v-card-text>
          <span class="mr-1">404</span>
          <span>Not Found</span>
        </v-card-text>
      </v-card>

      <error-card
        v-if="promptError"
        class="ma-3"
      />

      <v-snackbar
        v-model="notification"
      >
        <v-row>
          <v-col class="align-center d-flex">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span v-html="snack.message" />
          </v-col>

          <v-col class="text-right">
            <v-btn
              color="primary"
              @click="fireActionEvent(snack)"
            >
              {{ (snack.action && snack.action.name) || 'Close' }}
            </v-btn>
          </v-col>
        </v-row>
      </v-snackbar>

      <feature-modal />
      <settings-modal />
      <refresh-modal />
    </v-main>
  </v-app>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import PATHS from 'src/router/paths'

import { fireanalytics } from 'src/store/firebase'

const {
  mapGetters: mapAuthGetters,
  mapState: mapAuthState,
} = createNamespacedHelpers('auth')
const { mapState: mapLayoutState } = createNamespacedHelpers('layout')
const { mapGetters: mapToolsGetters, mapState: mapToolsState } = createNamespacedHelpers('tools')

export default {
  name: 'Layout',
  components: {
    Auth: () => import('src/auth/views/Auth'),
    ErrorCard: () => import('../components/ErrorCard'),
    Header: () => import('../components/Header'),
    Navigation: () => import('../components/Navigation'),
    FeatureModal: () => import('../components/FeatureModal'),
    RefreshModal: () => import('../components/RefreshModal'),
    SettingsModal: () => import('../components/SettingsModal'),
  },
  computed: {
    ...mapAuthGetters(['isActiveUser']),
    ...mapAuthState({
      demoMode: (state) => state.demoMode,
      isAutoAuthing: (state) => state.isAutoAuthing,
      isAuthCheckRequested: (state) => state.isAuthCheckRequested,
      isSigningIn: (state) => state.isSigningIn,
      user: (state) => state.user,
    }),
    ...mapLayoutState({
      promptError: (state) => state.promptError,
      theme: (state) => state.theme,
    }),
    ...mapToolsGetters(['isValidRoute', 'organizations', 'reportKey', 'selectedSearch']),
    ...mapToolsState({
      reportId: (state) => state.reportId,
      reportName: (state) => state.reportName,
      searchResults: (state) => state.searchResults,
      snack: (state) => state.snack,
      snackbar: (state) => state.snackbar,
      subId: (state) => state.subId,
    }),
    notification: {
      get() {
        return this.snackbar
      },
      set(value) {
        this.$store.commit('tools/snackbar', value, { root: true })
      },
    },
    showSignIn() {
      return !this.isActiveUser && !this.isSigningIn
    },
    customReportOptions() {
      return this.searchResults.endpoints['custom-reports']?.items?.list || []
    },
  },
  watch: {
    $route(to, from) {
      this.$store.commit('tools/adjuster', (to.query.adjuster === 'true' && true) || false)
      this.$store.commit('tools/reportId', to.params.reportId)
      this.$store.commit('tools/subId', to.params.subId || '*')

      this.$store.commit('tools/reportPath', to.params.reportPath)
      this.$store.commit('tools/reportName', to.params.reportName || to.name)

      this.setTitle(to, from || {})
    },
    isActiveUser() {
      const { reportId, reportName, subId } = this
      const to = { params: { reportId, reportName, subId } }
      const from = { params: { reportId, reportName, subId } }

      this.setTitle(to, from)
    },
  },
  methods: {
    fireActionEvent(event) {
      if (event && event.action) {
        this.$store.dispatch(event.action)
      } else {
        this.notification = false
      }
    },
    setTitle(to, from) {
      const titleMap = {
        dashboard: () => 'Dashboard',
        docs: () => 'User Docs',
        home: () => 'Home',

        discrepancy: (itemLabel) => `${itemLabel || 'Default'} | Discrepancy`,
        pacing: (itemLabel) => `${itemLabel || 'Default'} | Pacing`,

        [PATHS.PROGRAMMATIC.HEALTH.ADX]: (itemLabel) => `${itemLabel || 'Default'} | AdX Health`,
        [PATHS.PROGRAMMATIC.HEALTH.NETWORK]: (itemLabel) => `${itemLabel || 'Default'} | Network Health`,
        [PATHS.PROGRAMMATIC.YIELD.PARTNER]: (itemLabel) => `${itemLabel || 'Default'} | AdX & OB`,

        overview: (itemLabel) => `${itemLabel || 'All'} | Overview`,
        time: (itemLabel) => `${itemLabel || 'All'} | Time`,

        organization: (itemLabel) => `${itemLabel || 'All'} | Org`,
        person: (itemLabel) => `${itemLabel || 'All'} | Person`,
        project: (itemLabel) => `${itemLabel || 'All'} | Project`,
      }

      const { isActiveUser, reportKey } = this
      const toParams = to?.params || this
      const fromParams = from?.params || this
      const setReportId = (rid, sid) => (sid && sid !== '*' && `${rid}/${sid}`) || `${rid}`

      const { reportId: toReportId, reportName: toReportName, reportPath: toReportPath, subId: toSubId } = toParams
      const toIdentifier = (toReportId && setReportId(toReportId, toSubId)) || ''
      const getTitle = (toId) => {
        const titleKey = reportKey || 'home'
        const foundOrg = this.organizations.find((org) => org.billingCode === toReportId || org.publisherId?.toString() === toReportId)
        const identifierForPageTitle = (foundOrg && foundOrg.orgName) || toId
        const isCustomReportRoute = this.$route.name === 'custom-reports'
        const findCustomReportConfig = () => {
          const findCustomReport = () => {
            const customReportPermissions = foundOrg && foundOrg.access.permissions.customReports
            return Array.isArray(customReportPermissions) && customReportPermissions.find((customReport) => customReport.href === toReportName)
          }
          return isCustomReportRoute && findCustomReport()
        }
        const getCustomReportTitle = (customReport) => {
          const getPageTitleForList = () => !customReport && 'All | Custom Report'
          const getPageTitleForReport = () => customReport && customReport.name && `${customReport.name} | Custom Report`
          return isCustomReportRoute && (getPageTitleForList() || getPageTitleForReport() || 'Unknown | Custom Report')
        }
        const makePageTitle = (pageId) => typeof titleMap[titleKey] === 'function' && titleMap[titleKey](pageId)
        return makePageTitle(identifierForPageTitle) || getCustomReportTitle(findCustomReportConfig()) || '404 Not Found'
      }
      const page_title = (isActiveUser && getTitle(toIdentifier)) || 'Sign In'

      document.title = page_title

      const { location: { href: page_location, pathname: page_path } } = document
      const { reportId: fromReportId, reportName: fromReportName, reportPath: fromReportPath, subId: fromSubId } = fromParams
      const fromIdentifier = (fromReportId && setReportId(fromReportId, fromSubId)) || ''

      const fromPath = `${fromReportName || ''}/${(fromReportName && fromReportPath && `${fromReportPath}/`) || ''}${fromIdentifier}`
      const toPath = `${toReportName || ''}/${(toReportName && toReportPath && `${toReportPath}/`) || ''}${toIdentifier}`

      fireanalytics.event('page_view', {
        page_location,
        page_path,
        page_title,
      })

      fireanalytics.event('navigation', {
        category: 'Navigation',
        description: 'Update Route',
        label: `Update Route from "${fromPath}" to "${toPath}"`,
        pageLocation: page_location,
        pagePath: page_path,
        pageTitle: page_title,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~src/styles/variables";

main {
  background-color: $color-icewhite;
}
</style>
